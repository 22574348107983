import React from 'react';
import Content from '../views/RepairShop/Features';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Vehicle Care - en værkstedsløsning baseret på data.",
    lang: 'da-DK',
    description: 'En værkstedstjeneste for mekanikere, der forbindes direkte til køretøjerne 24/7. Designet til at proaktivt hjælpe kunderne.',
    meta: [{
      name: 'keywords',
      content: 'Værkstedsløsning'
    }]
  }}/>
);